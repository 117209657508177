.questions {
    margin-top: 16px;
    width: 100%;
}

.question-select {
    margin: 16px 0;
}

.questions-buttons {
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
    height: 42px;
}

.questions-buttons .back-button {
    width: calc(20% - 8px);
    height: 100%;
    margin-right: 8px;
    color: black;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-align: left;
}

.questions-buttons .next-button {
    height: 100%;
    border-radius: 12px;
    flex: 1;
    background-color: black;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-align: left;
}

.questions-buttons .next-button:hover {
    background-color: black;
}