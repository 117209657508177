.my-select-form {
    position: relative;
}

.my-select .my-select-label {
    position: relative;
    top: -0px;
    left: 0;
    margin-left: 0;
}

.my-select-form .my-select-label {
    position: relative;
    top: -0px;
    left: 0;
    margin-left: 0;
    white-space: normal;
    overflow-wrap: break-word;
}

.my-select {
    width: 100%;
}