.complete-questionnaire {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.complete-questionnaire .complete-information h1 {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
}

.complete-questionnaire .complete-information p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.15px;
    text-align: center;
}