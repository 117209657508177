.questionnaire-primary-data {
    margin-top: 16px;
    width: 100%;   
    height: 100%;
}


.country-and-city {
    position: relative;
    margin: 16px 0;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.country-and-city .left-input {
    position: relative;
    top: -22px;
    width: 50%;
    height: 100%;
}

.country-and-city .right-input {
    width: 100%;
    height: 100%;
}

.questionnaire-primary-data .data-picker-full-width {
    width: 100%;
}

.questionnaire-primary-data-marketing {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #1D1F22;
}

.questionnaire-primary-data .questionnaire-primary-data-button {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    height: 42px;
    background-color: black;
    border-radius: 12px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-align: left;
}

.questionnaire-primary-data .questionnaire-primary-data-button:hover {
    background-color: black;
}